import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createCampaign,
  updateCampaign,
  getAllCampaigns,
  getCampaignById,
  campaignLineByCampaignID,
  campaignInterviewVideo,
  updateCampaignLineStatusById,
  getCampaignDetails,
  getJobMainCategories,
  getJobSubCategories,
  getJobLocationRegion,
  getResumeFromCampaignLine,
  getInterviewVideosByUser,
  getCampaignLineByCampaignID,
  getAllActiveCampaign,
  getAllCampaignsByEnterprise,
  getInvitesAndInterview,
  createCampaignSetting,
  UpdateCampaignSetting,
  getCampaignSettingById,
  getJobLocationByIdibu,
  getSeekIdibuJobs,
  rateQuestionResponse,
  cloneCampagin,
  endCampaignJobStatus,
} from '../../api/campaigns';
import {
  Campaigns,
  CampaignsCreate,
  CampaignsUpdate,
  CampaignLineUpdate,
  CampaignsUpdateNew,
  ISettingResponse,
} from '../../modals/modals';
import { toastFailure, toastSuccess } from '../../utils/toast';
import moment from 'moment';

export enum StoreStage {
  Initial,
  Loading,
  Error,
}

export interface CampaignState {
  campaigns: Campaigns[];
  allCampaigns: Campaigns[];
  campaignById: any;
  allcampaignLinesByCampaignID: any;
  allVideoForcampaignId: [];
  mainJobCategory: any;
  subJobCategory: any;
  jobLocationRegion: any;
  allSeekIdibuCampaignJobs: any;
}

const initialState: CampaignState = {
  campaigns: [],
  allCampaigns: [],
  campaignById: null,
  allcampaignLinesByCampaignID: [],
  allVideoForcampaignId: [],
  mainJobCategory: [],
  subJobCategory: null,
  allSeekIdibuCampaignJobs: [],
  jobLocationRegion: null,
};

export const fetchCampaigns = createAsyncThunk('campaigns/fetch', async () => {
  return {
    campaignResponse: await getAllCampaigns(),
  };
});
export const fetchCampaignsByEnterprise = createAsyncThunk(
  'campaigns/fetchEnterprise',
  async (id: number) => {
    return {
      campaignResponse: await getAllCampaignsByEnterprise(id),
    };
  }
);

export const fetchCampaignByIdSlice = createAsyncThunk(
  'campaign/byid',
  async (id: any) => {
    return {
      campaignResponse: await getCampaignById(id),
    };
  }
);

export const createCampaigns = createAsyncThunk(
  'campaigns/create',
  async (data: CampaignsCreate) => {
    return { response: await createCampaign(data) };
  }
);

export const updateCampaigns = createAsyncThunk(
  'campaigns/update',
  async (data: any) => {
    return { response: await updateCampaign(data) };
  }
);

export const cloneCampaignByCampaignID = createAsyncThunk(
  'campaigns/clone',
  async (id: number) => {
    return { response: await cloneCampagin(id) };
  }
);

export const campaignLinesByCampaignID = createAsyncThunk(
  'CampaignLine/GetCampaigLineResponseByCampaignID',
  async (data: CampaignsUpdateNew) => {
    return { response: await campaignLineByCampaignID(data) };
  }
);
export const getCampaignLinesByCampaignID = createAsyncThunk(
  'CampaignLine/singleGetCampaigLineResponseByCampaignID',
  async (id: number) => {
    return { response: await getCampaignLineByCampaignID(id) };
  }
);

export const updateCampaignLineStatus = createAsyncThunk(
  '/CampaignLine/UpdateCampaignLineStatus',
  async (data: CampaignLineUpdate) => {
    return { response: await updateCampaignLineStatusById(data) };
  }
);

export const campaignInterviewVideos = createAsyncThunk(
  'campaign/InterviewVideos',
  async (campaignId: CampaignsUpdate) => {
    return { response: await campaignInterviewVideo(campaignId) };
  }
);

export const getCampaignLineDetails = createAsyncThunk(
  'campaignLine/details',
  async (data: CampaignsUpdateNew) => {
    return { response: await getCampaignDetails(data) };
  }
);
export const getCampaignLineResume = createAsyncThunk(
  'campaignLine/resume',
  async (campaignId: CampaignsUpdate) => {
    return { response: await getResumeFromCampaignLine(campaignId) };
  }
);
export const getInteriewVideosByUsers = createAsyncThunk(
  'campaignLine/resume',
  async (campaignId: CampaignsUpdate) => {
    return { response: await getInterviewVideosByUser(campaignId) };
  }
);
export const getAllActiveCampaigns = createAsyncThunk(
  'campaig/activeDashboard',
  async (campaignId: number) => {
    return { response: await getAllActiveCampaign(campaignId) };
  }
);

export const getJobMainCategoriesCampaign = createAsyncThunk(
  'campaign/jobmaincategories',
  async () => {
    return { response: await getJobMainCategories() };
  }
);
export const getJobSubCategoriesCampaign = createAsyncThunk(
  'campaign/jobsubcategories',
  async (id: number) => {
    return { response: await getJobSubCategories(id) };
  }
);

export const getSeekIdibuJobsById = createAsyncThunk(
  'campaign/seekIdibuJobs',
  async (data: any) => {
    return { response: await getSeekIdibuJobs(data) };
  }
);

export const getJobLocationRegionCampaign = createAsyncThunk(
  'campaign/jobLocationRegion',
  async () => {
    return { response: await getJobLocationRegion() };
  }
);

export const getJobLocByIdibu = createAsyncThunk(
  'campaign/jobLocationByIdibu',
  async () => {
    return { response: await getJobLocationByIdibu() };
  }
);

export const getInvitesAndInterviews = createAsyncThunk(
  'campaign/invitesAndInterview',
  async (id: number) => {
    return { response: await getInvitesAndInterview(id) };
  }
);

export const rateQuestionResponseByCampaignId = createAsyncThunk(
  'campaign/rateQuestionResponse',
  async (id: number) => {
    return {
      response: await rateQuestionResponse(id),
    };
  }
);

export const createJobSettings = createAsyncThunk(
  'Campaign/createSetting',
  async (data: any) => {
    return { response: await createCampaignSetting(data) };
  }
);
export const getJobSettings = createAsyncThunk(
  'Campaign/createSetting',
  async (data: any) => {
    return {
      response: await getCampaignSettingById(
        data.campaignId,
        data.enterpriseId
      ),
    };
  }
);
export const updateJobSettings = createAsyncThunk(
  'Campaign/updateSetting',
  async (data: ISettingResponse) => {
    return { response: await UpdateCampaignSetting(data) };
  }
);

export const endJobStatus = createAsyncThunk(
  'Campaign/endJobStatus',
  async (id: any) => {
    return { response: await endCampaignJobStatus(id) };
  }
);

export const campaignSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    updateCampaignStatus: (state, action) => {
      const objIndex = state?.campaigns.findIndex(
        (obj) => obj.pk_Campaign_ID == action.payload.campaignId
      );

      state.campaigns[objIndex].Campaign_Status = action.payload.status;
    },
    filterCampaignStatus: (state, action) => {
      let campaign = [];
      if (action.payload != null) {
        campaign = state.allCampaigns.filter(
          (obj) => obj.Campaign_Status == action.payload
        );
        if (action.payload === 100) {
          campaign = campaign.filter(
            (obj) =>
              !moment(obj.Campaign_EndDate, 'YYYY/MM/DD').isBefore(moment())
          );
        }

        state.campaigns = campaign;
      } else {
        state.campaigns = state.allCampaigns;
      }
    },
    filterCampaignByDate: (state, action) => {
      state.campaigns = state.allCampaigns.sort((a, b) => {
        const dateA = new Date(
          action.payload.isCreateed ? a.Campaign_StartDate : a.Campaign_EndDate
        );
        const dateB = new Date(
          action.payload.isCreateed ? b.Campaign_StartDate : b.Campaign_EndDate
        );
        return action.payload.isDesc
          ? dateB.getTime() - dateA.getTime()
          : dateA.getTime() - dateB.getTime();
      });
    },
  },
  extraReducers: (builder) => {
    ///Fetch Campaigns
    builder.addCase(fetchCampaignsByEnterprise.pending, (state) => {
      // state.campaigns = StoreStage.Loading;
    });
    builder.addCase(fetchCampaignsByEnterprise.fulfilled, (state, action) => {
      if (action.payload.campaignResponse.successful) {
        state.campaigns = action.payload.campaignResponse.data;
        state.allCampaigns = action.payload.campaignResponse.data;
      } else {
        if (!action.payload.campaignResponse.successful) {
          toastFailure(action.payload.campaignResponse.message);
        }
      }
    });
    builder.addCase(fetchCampaignsByEnterprise.rejected, (state) => {
      // state.campaigns = StoreStage.Error;
    });

    builder.addCase(fetchCampaignByIdSlice.fulfilled, (state, action) => {
      if (action.payload.campaignResponse.successful) {
        state.campaignById = action.payload.campaignResponse.data;
      }
    });

    ///Create Campaigns
    builder.addCase(createCampaigns.fulfilled, (state, action) => {
      // const campaignsDataArg = action?.meta?.arg ? action?.meta?.arg : {};
      // const campaignId = action?.payload?.response?.data ? action?.payload?.response?.data : null ;
      // const combinedCampaignObject = {...campaignsDataArg, pk_Campaign_ID: campaignId}
      if (action.payload.response.successful) {
        // state.campaignId = action.payload.response
        toastSuccess(`Job created with ID ${action.payload.response.data}`);
      } else {
        if (!action.payload.response.successful) {
          toastFailure(action.payload.response.message);
        }
      }
    });

    ///Update Campaigns
    builder.addCase(updateCampaigns.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        toastSuccess(action.payload.response.data);
      } else {
        if (!action.payload.response.successful) {
          toastFailure(action.payload.response.message);
        }
      }
    });

    ///get all questions and answers for particular campaign
    builder.addCase(campaignLinesByCampaignID.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        if (action.payload.response.data.length > 0) {
          state.allcampaignLinesByCampaignID = action.payload.response.data;
        } else {
          state.allcampaignLinesByCampaignID = [];
        }
      } else {
        state.allcampaignLinesByCampaignID = [];
      }
    });

    //update campaign line status
    builder.addCase(updateCampaignLineStatus.fulfilled, (state, action) => {});

    ///get all video for particular campaign
    builder.addCase(campaignInterviewVideos.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        state.allVideoForcampaignId = action.payload.response.data;
      } else {
        state.allVideoForcampaignId = [];
      }
    });

    ///get campaign job main category
    builder.addCase(getJobMainCategoriesCampaign.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        state.mainJobCategory = action.payload.response.data;
      } else {
        state.mainJobCategory = null;
      }
    });

    ///get campaign job sub category
    builder.addCase(getJobSubCategoriesCampaign.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        state.subJobCategory = action.payload.response.data;
      } else {
        state.subJobCategory = null;
      }
    });

    ///get campaign job sub category
    builder.addCase(getSeekIdibuJobsById.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        state.allSeekIdibuCampaignJobs = action.payload.response.data;
      } else {
        state.allSeekIdibuCampaignJobs = [];
      }
    });

    ///get campaign job location region
    builder.addCase(getJobLocationRegionCampaign.fulfilled, (state, action) => {
      if (action.payload.response.successful) {
        state.jobLocationRegion = action.payload.response.data;
      } else {
        state.jobLocationRegion = null;
      }
    });
  },
});

export const {
  updateCampaignStatus,
  filterCampaignStatus,
  filterCampaignByDate,
} = campaignSlice.actions;

export default campaignSlice.reducer;
